// src/styles/_fonts.scss
@font-face {
  font-family: "Nalieta";
  src: url("../../fonts/Nanum_Myeongjo/NanumMyeongjo-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../fonts//OTF/Switzer-Regular.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  background-color: #090909;
  color: #eae2c3;
  font-family: "SF Pro Display"; // Use 'serif' as fallback if needed
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Nalieta"; // Use 'serif' as fallback if needed

  span {
    color: #c09462;
  }
}

.overflow_hidden {
  @media (max-width: "450px") {
    overflow-x: hidden;
  }
}
